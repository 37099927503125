exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-archive-policy-[source]-jsx": () => import("./../../../src/pages/archive/policy/[source].jsx" /* webpackChunkName: "component---src-pages-archive-policy-[source]-jsx" */),
  "component---src-pages-auth-password-reset-index-jsx": () => import("./../../../src/pages/auth_password_reset/index.jsx" /* webpackChunkName: "component---src-pages-auth-password-reset-index-jsx" */),
  "component---src-pages-auth-required-index-jsx": () => import("./../../../src/pages/auth_required/index.jsx" /* webpackChunkName: "component---src-pages-auth-required-index-jsx" */),
  "component---src-pages-blacklisted-customer-index-jsx": () => import("./../../../src/pages/blacklisted_customer/index.jsx" /* webpackChunkName: "component---src-pages-blacklisted-customer-index-jsx" */),
  "component---src-pages-computer-network-assignment-index-jsx": () => import("./../../../src/pages/computer-network-assignment/index.jsx" /* webpackChunkName: "component---src-pages-computer-network-assignment-index-jsx" */),
  "component---src-pages-computer-science-homework-help-index-jsx": () => import("./../../../src/pages/computer-science-homework-help/index.jsx" /* webpackChunkName: "component---src-pages-computer-science-homework-help-index-jsx" */),
  "component---src-pages-confidentiality-policy-index-jsx": () => import("./../../../src/pages/confidentiality-policy/index.jsx" /* webpackChunkName: "component---src-pages-confidentiality-policy-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-cpp-homework-index-jsx": () => import("./../../../src/pages/cpp-homework/index.jsx" /* webpackChunkName: "component---src-pages-cpp-homework-index-jsx" */),
  "component---src-pages-cpp-tutor-index-jsx": () => import("./../../../src/pages/cpp-tutor/index.jsx" /* webpackChunkName: "component---src-pages-cpp-tutor-index-jsx" */),
  "component---src-pages-csharp-homework-index-jsx": () => import("./../../../src/pages/csharp-homework/index.jsx" /* webpackChunkName: "component---src-pages-csharp-homework-index-jsx" */),
  "component---src-pages-customer-faq-index-jsx": () => import("./../../../src/pages/customer-faq/index.jsx" /* webpackChunkName: "component---src-pages-customer-faq-index-jsx" */),
  "component---src-pages-data-science-homework-index-jsx": () => import("./../../../src/pages/data-science-homework/index.jsx" /* webpackChunkName: "component---src-pages-data-science-homework-index-jsx" */),
  "component---src-pages-database-homework-index-jsx": () => import("./../../../src/pages/database-homework/index.jsx" /* webpackChunkName: "component---src-pages-database-homework-index-jsx" */),
  "component---src-pages-discount-policy-index-jsx": () => import("./../../../src/pages/discount-policy/index.jsx" /* webpackChunkName: "component---src-pages-discount-policy-index-jsx" */),
  "component---src-pages-do-my-computer-science-homework-index-jsx": () => import("./../../../src/pages/do-my-computer-science-homework/index.jsx" /* webpackChunkName: "component---src-pages-do-my-computer-science-homework-index-jsx" */),
  "component---src-pages-excel-homework-index-jsx": () => import("./../../../src/pages/excel-homework/index.jsx" /* webpackChunkName: "component---src-pages-excel-homework-index-jsx" */),
  "component---src-pages-experts-[slug]-jsx": () => import("./../../../src/pages/experts/[slug].jsx" /* webpackChunkName: "component---src-pages-experts-[slug]-jsx" */),
  "component---src-pages-experts-index-jsx": () => import("./../../../src/pages/experts/index.jsx" /* webpackChunkName: "component---src-pages-experts-index-jsx" */),
  "component---src-pages-how-it-works-index-jsx": () => import("./../../../src/pages/how-it-works/index.jsx" /* webpackChunkName: "component---src-pages-how-it-works-index-jsx" */),
  "component---src-pages-html-homework-index-jsx": () => import("./../../../src/pages/html-homework/index.jsx" /* webpackChunkName: "component---src-pages-html-homework-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-java-homework-index-jsx": () => import("./../../../src/pages/java-homework/index.jsx" /* webpackChunkName: "component---src-pages-java-homework-index-jsx" */),
  "component---src-pages-java-problem-solver-index-jsx": () => import("./../../../src/pages/java-problem-solver/index.jsx" /* webpackChunkName: "component---src-pages-java-problem-solver-index-jsx" */),
  "component---src-pages-java-tutor-index-jsx": () => import("./../../../src/pages/java-tutor/index.jsx" /* webpackChunkName: "component---src-pages-java-tutor-index-jsx" */),
  "component---src-pages-javascript-homework-index-jsx": () => import("./../../../src/pages/javascript-homework/index.jsx" /* webpackChunkName: "component---src-pages-javascript-homework-index-jsx" */),
  "component---src-pages-javascript-tutor-index-jsx": () => import("./../../../src/pages/javascript-tutor/index.jsx" /* webpackChunkName: "component---src-pages-javascript-tutor-index-jsx" */),
  "component---src-pages-latest-orders-index-jsx": () => import("./../../../src/pages/latest-orders/index.jsx" /* webpackChunkName: "component---src-pages-latest-orders-index-jsx" */),
  "component---src-pages-machine-learning-homework-index-jsx": () => import("./../../../src/pages/machine-learning-homework/index.jsx" /* webpackChunkName: "component---src-pages-machine-learning-homework-index-jsx" */),
  "component---src-pages-matlab-homework-index-jsx": () => import("./../../../src/pages/matlab-homework/index.jsx" /* webpackChunkName: "component---src-pages-matlab-homework-index-jsx" */),
  "component---src-pages-matlab-solver-index-jsx": () => import("./../../../src/pages/matlab-solver/index.jsx" /* webpackChunkName: "component---src-pages-matlab-solver-index-jsx" */),
  "component---src-pages-matlab-tutor-index-jsx": () => import("./../../../src/pages/matlab-tutor/index.jsx" /* webpackChunkName: "component---src-pages-matlab-tutor-index-jsx" */),
  "component---src-pages-mysql-homework-index-jsx": () => import("./../../../src/pages/mysql-homework/index.jsx" /* webpackChunkName: "component---src-pages-mysql-homework-index-jsx" */),
  "component---src-pages-our-experts-index-jsx": () => import("./../../../src/pages/our-experts/index.jsx" /* webpackChunkName: "component---src-pages-our-experts-index-jsx" */),
  "component---src-pages-php-homework-index-jsx": () => import("./../../../src/pages/php-homework/index.jsx" /* webpackChunkName: "component---src-pages-php-homework-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-programming-homework-help-index-jsx": () => import("./../../../src/pages/programming-homework-help/index.jsx" /* webpackChunkName: "component---src-pages-programming-homework-help-index-jsx" */),
  "component---src-pages-programming-tutor-index-jsx": () => import("./../../../src/pages/programming-tutor/index.jsx" /* webpackChunkName: "component---src-pages-programming-tutor-index-jsx" */),
  "component---src-pages-python-homework-index-jsx": () => import("./../../../src/pages/python-homework/index.jsx" /* webpackChunkName: "component---src-pages-python-homework-index-jsx" */),
  "component---src-pages-python-tutor-index-jsx": () => import("./../../../src/pages/python-tutor/index.jsx" /* webpackChunkName: "component---src-pages-python-tutor-index-jsx" */),
  "component---src-pages-r-homework-index-jsx": () => import("./../../../src/pages/r-homework/index.jsx" /* webpackChunkName: "component---src-pages-r-homework-index-jsx" */),
  "component---src-pages-rating-and-awards-index-jsx": () => import("./../../../src/pages/rating-and-awards/index.jsx" /* webpackChunkName: "component---src-pages-rating-and-awards-index-jsx" */),
  "component---src-pages-refund-policy-index-jsx": () => import("./../../../src/pages/refund-policy/index.jsx" /* webpackChunkName: "component---src-pages-refund-policy-index-jsx" */),
  "component---src-pages-registration-index-jsx": () => import("./../../../src/pages/registration/index.jsx" /* webpackChunkName: "component---src-pages-registration-index-jsx" */),
  "component---src-pages-ruby-homework-index-jsx": () => import("./../../../src/pages/ruby-homework/index.jsx" /* webpackChunkName: "component---src-pages-ruby-homework-index-jsx" */),
  "component---src-pages-scala-homework-index-jsx": () => import("./../../../src/pages/scala-homework/index.jsx" /* webpackChunkName: "component---src-pages-scala-homework-index-jsx" */),
  "component---src-pages-sign-in-index-jsx": () => import("./../../../src/pages/sign-in/index.jsx" /* webpackChunkName: "component---src-pages-sign-in-index-jsx" */),
  "component---src-pages-sql-homework-index-jsx": () => import("./../../../src/pages/sql-homework/index.jsx" /* webpackChunkName: "component---src-pages-sql-homework-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-vba-homework-index-jsx": () => import("./../../../src/pages/vba-homework/index.jsx" /* webpackChunkName: "component---src-pages-vba-homework-index-jsx" */),
  "component---src-pages-visual-basic-homework-index-jsx": () => import("./../../../src/pages/visual-basic-homework/index.jsx" /* webpackChunkName: "component---src-pages-visual-basic-homework-index-jsx" */)
}

