/**
 * @summary v1.0.0
 */

/**
 * @typedef {Object} Breakpoints
 * @property {string} xs - extra small devices (portrait phones, less than 576px)
 * @property {string} sm - small devices (landscape phones, 576px and up)
 * @property {string} md - medium devices (tablets, 768px and up)
 * @property {string} lg - large devices (desktops, 992px and up)
 * @property {string} xl - extra large devices (1200px and up)
 */

/**
 * @see https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
 * @type {Breakpoints}
 */
export const breakpoints = ['0px', '576px', '768px', '992px', '1200px'];

/* eslint-disable prefer-destructuring */
breakpoints.xs = breakpoints[0]; // 0px
breakpoints.sm = breakpoints[1]; // 576px
breakpoints.md = breakpoints[2]; // 768px
breakpoints.lg = breakpoints[3]; // 992px
breakpoints.xl = breakpoints[4]; // 1200px

export const fontSizes = [
  '14px', // 0
  '16px', // 1
  '18px', // 2
  '24px', // 3
  '28px', // 4
  '32px', // 5
  '36px', // 6
  '40px', // 7
  '44px', // 8
];

fontSizes.h1 = fontSizes[8];
fontSizes.h1m = fontSizes[5];
fontSizes.h2 = fontSizes[6];
fontSizes.h2m = fontSizes[5];
fontSizes.h3 = fontSizes[4];
fontSizes.h3m = fontSizes[3];
fontSizes.subtitle1 = fontSizes[3];
fontSizes.subtitle2 = fontSizes[2];
fontSizes.subtitlem = fontSizes[2];
fontSizes.body = fontSizes[1];
fontSizes.caption = fontSizes[0];
fontSizes.button = fontSizes[2];
fontSizes.menu = fontSizes[2];
fontSizes.error = fontSizes[0];

/**
 * Custom theme
 * @see https://rebassjs.org/theming/
 * @see https://rebassjs.org/variants/
 */

const body = {
  m: 0,
  mb: 3,
};

const h1 = {
  color: '#1f1f1f',
  fontSize: ['h1m', null, null, 'h1'],
  lineHeight: ['h1m', null, null, 'h1'],
  fontFamily: 'heading',
  mt: 0,
  mb: '24px',
};

const h2 = {
  color: 'basic.5',
  fontSize: ['h2m', null, null, 'h2'],
  lineHeight: ['h2m', null, null, 'h2'],
  fontFamily: 'heading',
  mt: 0,
  mb: 3,
};

const h3 = {
  color: 'basic.5',
  fontSize: ['h3m', null, null, 'h2'],
  lineHeight: ['h3m', null, null, 'h2'],
  fontFamily: 'heading',
  mt: 0,
  mb: 0,
};

const subtitle1 = {
  color: 'basic.5',
  fontSize: ['subtitlem', null, null, 'subtitle1'],
  lineHeight: ['subtitlem', null, null, 'subtitle1'],
  fontFamily: 'body',
  mt: 0,
  mb: 3,
};

const caption = {
  color: 'basic.3',
  fontSize: 'caption',
  lineHeight: 'caption',
  fontFamily: 'body',
};

const subtitle2 = {
  color: 'basic.5',
  fontSize: ['subtitlem', null, null, 'subtitle2'],
  lineHeight: ['subtitlem', null, null, 'subtitle2'],
  fontFamily: 'body',
  mt: 0,
  mb: 3,
};

const link = {
  color: 'secondary.0',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },

  '&.activeLink': {
    pointerEvents: 'none',
    color: 'primary',
  },
};

const ul = {
  p: 0,
  m: 0,
  listStyle: 'none',

  '& > li': {
    position: 'relative',
    mb: 3,
    pl: 3,
  },

  '& > li::before': {
    position: 'absolute',
    top: 2,
    left: 0,
    content: '""',
    display: 'block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: 'basic.4',
  },
};

const ol = {
  p: 0,
  m: 0,
  listStyle: 'none',
  counterReset: 'listCounter',

  '& > li': {
    position: 'relative',
    mb: 3,
    pl: 4,
    counterIncrement: 'listCounter',
  },

  '& > li::before': {
    position: 'absolute',
    left: 0,
    content: 'counter(listCounter)',
    display: 'block',
    width: '32px',
    flexShrink: 0,
    color: '#C4C4C4',
  },
};

const theme = {
  breakpoints,
  fontSizes,
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Archivo Black, sans-serif;',
  },
  lineHeights: {
    h1: '58px',
    h1m: '40px',
    h2: '48px',
    h2m: '40px',
    h3: '40px',
    h3m: '32px',
    subtitle1: '32px',
    subtitle2: '28px',
    subtitlem: '24px',
    body: '27px',
    button: '27px',
    menu: '20px',
    caption: '18px',
  },
  fontWeights: {
    body: '400',
    bold: '700',
  },
  colors: {
    basic: [
      '#FFFFFF', // 0
      '#F0F0F0', // 1
      '#E0E0E0', // 2
      '#9E9E9E', // 3
      '#616161', // 4
      '#1A1A1A', // 5
    ],
    primary: '#EB4B5B',
    secondary: ['#4990EB', '#EDF4FD', '#FEF6F7'],
    status: ['#0ABF53', '#FFEB3B', '#F44336'],
    ok: '#0ABF53',
    warn: '#FFEB3B',
    error: '#F44336',
  },
  space: [0, 4, 8, 16, 32, 64],
  radii: {
    circle: '50%',
    small: '2px',
  },
  shadows: {
    small: '0 2px 4px rgba(10, 46, 92, 0.16)',
    medium: '0 6px 16px rgba(10, 46, 92, 0.12)',
    large: '20px 20px 30px rgba(10, 46, 92, 0.08)',
  },
  variants: {
    link,
    landingLink: {
      display: 'block',
      mb: 3,
      width: ['100%', null, null, '340px'],
      color: 'basic.3',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },

      '&.activeLink': {
        pointerEvents: 'none',
        color: 'primary',
      },
    },
    headerLink: {
      color: 'basic.5',
      textDecoration: 'none',

      '&:hover': {
        color: 'secondary.0',
      },

      '&.activeLink': {
        pointerEvents: 'none',
        color: 'primary',
      },
    },
    policyLink: {
      display: 'block',
      fontWeight: '500',
      paddingLeft: '20px',
      textDecoration: 'none',
      borderWidth: '0',
      borderLeftWidth: '1px',
      borderColor: 'basic.4',
      borderStyle: 'solid',
      color: 'basic.5',
      '& > div': {
        pl: '20px',
        pb: '10px',
      },
      '&:hover': {
        textDecoration: 'underline',
      },

      '&.activeLink': {
        fontWeight: 'bold',
        borderColor: 'secondary.0',
        background: 'linear-gradient(89.52deg, #EDF4FD 0.36%, #FFFFFF 99.59%)',
        borderLeftWidth: '2px',
        '& > div': {
          py: '30px',
        },
      },
    },
    footerLink: {
      display: 'block',
      mb: 3,
      color: 'basic.0',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },

      '&.activeLink': {
        pointerEvents: 'none',
        color: 'primary',
      },
    },
    card: {
      p: 3,
      bg: '#fff',
      border: '1px solid #F1F1F1',
      boxShadow: 'medium',
      borderRadius: 'small',
    },
    darkCard: {
      color: '#333',
      bg: '#F5F6F8',
      border: '1px solid #DBDFE6',
    },
    ul,
    ol,
    content: {
      p: body,
      h1,
      h2,
      h3,
      a: link,
      ol,
      ul,
    },
  },
  text: {
    body,
    h1,
    h2,
    h3,
    subtitle1,
    subtitle2,
    caption,
  },
  buttons: {
    primary: {
      fontSize: 'button',
      color: 'basic.0',
      fontWeight: 'bold',
      bg: 'primary',
      borderRadius: 'small',
      textAlign: 'center',
      cursor: 'pointer',

      '&:hover, &:focus': {
        bg: '#FF2F44',
        outline: 0,
      },

      '&:active': {
        boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    primaryLight: {
      fontSize: 'button',
      color: 'basic.5',
      fontWeight: 'bold',
      bg: 'basic.0',
      borderRadius: 'small',
      textAlign: 'center',
      cursor: 'pointer',

      '&:hover, &:focus': {
        bg: 'secondary.0',
        outline: 0,
      },

      '&:active': {
        boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    secondary: {
      fontSize: 'button',
      color: 'basic.5',
      borderWidth: '1px',
      borderColor: 'basic.5',
      borderStyle: 'solid',
      fontWeight: 'bold',
      bg: 'secondary.1',
      borderRadius: 'small',
      textAlign: 'center',
      cursor: 'pointer',

      '&:hover, &:focus': {
        bg: 'basic.1',
        outline: 0,
      },

      '&:active, &:focus': {
        borderColor: 'secondary.0',
        bg: 'secondary.1',
      },

      '&:disabled': {
        color: 'basic.3',
        borderColor: 'basic.3',
        cursor: 'not-allowed',
      },
    },
    secondaryDark: {
      fontSize: 'button',
      color: 'basic.0',
      borderWidth: '1px',
      borderColor: 'basic.0',
      borderStyle: 'solid',
      fontWeight: 'bold',
      bg: 'basic.5',
      borderRadius: 'small',
      textAlign: 'center',
      cursor: 'pointer',

      '&:hover, &:focus': {
        bg: 'basic.4',
        outline: 0,
      },

      '&:active': {
        color: 'basic.5',
        bg: 'basic.0',
      },

      '&:disabled': {
        color: 'basic.3',
        borderColor: 'basic.3',
        cursor: 'not-allowed',
      },
    },
  },
  forms: {
    label: {
      pb: 2,
    },
    input: {
      color: 'basic.5',
      backgroundColor: 'basic.1',
      height: '50px',
      borderWidth: '1px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderRadius: 'small',
      outline: 0,

      '&::placeholder': {
        color: 'basic.5',
      },

      '&:hover': {
        bg: 'secondary.1',
      },

      '&:focus': {
        borderColor: 'secondary.0',
      },

      '&:disabled': {
        position: 'relative',
        zIndex: 1,
        backgroundColor: '#F0F2F5',
        borderColor: '#C3C9D5',
      },

      '&[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      '&.error': {
        borderColor: 'error',
      },
    },
    select: {
      p: 2,
      color: 'basic.0',
      backgroundColor: 'basic.1',
      borderColor: '#DBDFE6',
      outline: 0,

      '&:disabled': {
        backgroundColor: '#F0F2F5',
        borderColor: '#C3C9D5',
      },

      '&:focus': {
        borderColor: 'basic.0',
      },

      '&::-ms-expand': {
        display: 'none',
      },

      '&.error': {
        borderColor: 'error',
      },
    },
    checkbox: {
      width: '24px',
      minWidth: '24px',
      margin: 0,
      mr: '8px',
      padding: 0,
      bg: 'basic.1',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: 'small',
      outline: 0,
      color: 'transparent',
      transition: 'border-color 0.3s',
      cursor: 'pointer',

      svg: {
        strokeWidth: 4,
      },

      '&:hover': {
        backgroundColor: 'secondary.1',
      },

      'input:focus ~ &': {
        borderColor: 'secondary.0',
      },

      'input:checked ~ &': {
        svg: {
          stroke: 'secondary.0',
        },
      },

      'input:active ~ &': {
        svg: {
          stroke: 'secondary.0',
        },
      },

      '&.error': {
        borderColor: 'error',

        svg: {
          stroke: 'error',
        },
      },

      'input:disabled:active ~ &': {
        opacity: 0.5,
      },

      'input:disabled ~ &': {
        color: 'basic.2',

        '&:hover': {
          bg: 'basic.1',
          cursor: 'not-allowed',
        },

        '& svg': {
          stroke: 'basic.3',
        },
      },
    },
  },
  alerts: {
    'field-error': {
      display: 'inline-block',
      mt: 1,
      fontSize: 0,
      color: 'error',
    },
    'form-error': {
      mb: 3,
      py: 2,
      px: 3,
      fontSize: 1,
      textAlign: 'center',
      bg: 'error',
      color: 'white',
    },
  },
};

export default theme;
