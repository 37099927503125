/* eslint-disable */

/**
 * Implement Gatsby's Browser APIs in this file.
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import ModalProvider from '@front/hooks/useModal/modalProvider';
import theme from '@front/ds/themes/assignmentcore.com';
import '@fontsource/archivo';
import '@fontsource/archivo/300.css';
import '@fontsource/archivo/400-italic.css';
import '@fontsource/archivo/700.css';

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
  };
};

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ModalProvider>{element}</ModalProvider>
  </ThemeProvider>
);

/**
 * @see https://github.com/gatsbyjs/gatsby/issues/23676
 * @returns {Promise<void>}
 */
export const onClientEntry = () => {
  const polyfills = [];

  if (typeof IntersectionObserver === 'undefined') {
    polyfills.push(import('intersection-observer'));
  }

  if (!Element.prototype.closest) {
    polyfills.push(import('element-closest').then(({ default: polyfill }) => polyfill(window)));
  }

  if (typeof CustomEvent === 'undefined') {
    polyfills.push(import('custom-event-polyfill'));
  }

  return Promise.all(polyfills);
};
